import React, { useEffect, useState } from 'react';
import "../styles/Travel.css";
import { Navigate } from 'react-router-dom';
import { TRAVEL_INFO } from '../Constants';

const Travel: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true);
        }
    }, []);

    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });




    return (
        !showPopup ?
            <div className="travel-container">
                <h1 className="travel-heading">{TRAVEL_INFO.title}</h1>
                <p className="travel-text">{TRAVEL_INFO.desc}</p>
                <ul className="travel-links">
                    {TRAVEL_INFO.links.map((link, key) => {
                        return <li key={key}><a href={link.link} target="_blank" rel="noopener noreferrer">{link.title}</a></li>
                    })}
                </ul>
            </div >
            : <Navigate to={"/"} />
    );
};

export default Travel;

import React, { Fragment, useEffect, useState } from 'react'
import "../styles/Default.css"
import HotelInfo from '../components/HotelInfo';
import { Navigate } from 'react-router-dom';
const Hotel: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, []);
    return (
        !showPopup ? <Fragment>
            <HotelInfo />
        </Fragment>
            : <Navigate to={"/"} />

    )
}
export default Hotel
import React, { useEffect } from 'react';
import '../styles/FAQ.css';
import { FAQ_INFO } from '../Constants';

const FAQ: React.FC = () => {
    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });

    return (
        <div className="faq-wrapper">
            {/* <img className="faq-thick-border" src="/assets/images/itinerary_new/cropped/thick_border.png" alt="thick_border" /> */}
            <img className="faq-thin-border top" src="/assets/images/itinerary_new/cropped/top_thin_border.png" alt="thin_border" />
            <div className="faq-container">
                <img className="faq-heading" src="/assets/images/artifacts/cropped/faq_title.png" alt="faq_title" />
                {FAQ_INFO.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div className="faq-question">
                            <img src='/assets/images/artifacts/cropped/seperator_icon.png' alt="seperator" className="faq-seperator-icon" />
                            {faq.question}
                        </div>
                        <div className="faq-answer">{faq.answer}</div>
                    </div>
                ))}
            </div>
            <img className="faq-thin-border faq-thin-border-bottom" src="/assets/images/itinerary_new/cropped/top_thin_border.png" alt="thin_border" />
        </div>
    );
};

export default FAQ;

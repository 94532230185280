import React, { useEffect, useState } from 'react'
import "../styles/Default.css"
import { Navigate } from 'react-router-dom';
const Concierge: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, [])
    return (
        !showPopup ?
            <div className='default'>
                <h1>Concierge details and tailored assistance are on their way.</h1>
                <p className='subtext'>For any questions, please email sabiandani25@gmail.com</p>
            </div>
            : <Navigate to={"/"} />

    )
}
export default Concierge

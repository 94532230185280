// App.tsx
import React, { useEffect, useState, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AdminPanel from './pages/AdminPanel';
import CustomNavbar from './components/CustomNavbar';
import Wedding from './pages/Wedding';
import Itinerary from './pages/Itinerary';
import Story from './pages/Story';
import Hotel from './pages/Hotel';
import Info from './pages/Info';
import Travel from './pages/Travel';
import Explore from './pages/Explore';
import Gifts from './pages/Gifts';
import Concierge from './pages/Concierge';
import LogoComponent from './components/Logo';
import RSVP from './pages/Rsvp';
import { MusicProvider } from './components/MusicContext';

const App: React.FC = () => {
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [animate, setAnimate] = useState(false);
  const [showLogo, setShowLogo] = useState(true);

  useEffect(() => {
    const showLogoFromStorage = sessionStorage.getItem('showCenterLogo') === 'true';
    setShowLogo(showLogoFromStorage);
  }, []);

  return (
      <Router>
        {window.location.pathname !== '/' && <CustomNavbar isAdmin={isAdmin} />}
        <LogoComponent showLogo={showLogo} animate={animate} />
        <Routes>
          <Route path="/" element={<LandingPage setIsAdmin={setIsAdmin} setAnimate={setAnimate} setShowLogo={setShowLogo} />} />
          <Route path="admin" element={<AdminPanel />} />
          <Route path="wedding" element={<Wedding />} />
          <Route path="itinerary" element={<Itinerary />} />
          <Route path="story" element={<Story />} />
          <Route path="hotel" element={<Hotel />} />
          <Route path="info" element={<Info />} />
          <Route path="travel" element={<Travel />} />
          <Route path="explore" element={<Explore />} />
          <Route path="gifts" element={<Gifts />} />
          <Route path="rsvp" element={<RSVP />} />
          <Route path="concierge" element={<Concierge />} />
        </Routes>
      </Router>
  );
};

export default App;

import React, { useEffect, useState } from 'react'
import "../styles/Default.css"
import RSVPForm from '../components/RsvpForm';
import { Navigate } from 'react-router-dom';
const RSVP: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, [])
    return (
        !showPopup ?
            <RSVPForm />
            : <Navigate to={"/"} />
    )
}
export default RSVP

import React, { useEffect, useRef, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import '../styles/CustomNavbar.css';

interface NavbarProps {
    isAdmin: boolean;
}

const CustomNavbar: React.FC<NavbarProps> = ({ isAdmin }) => {
    const [expanded, setExpanded] = useState(false);
    const navbarRef = useRef<HTMLElement | null>(null);
    const location = useLocation();

    const handleClickOutside = (event: MouseEvent) => {
        if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
            setExpanded(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <Navbar ref={navbarRef} expand="lg" fixed="top" expanded={expanded} onToggle={() => setExpanded(!expanded)} style={{ backgroundColor: window.location.pathname === '/explore' && window.innerWidth < 768 ? '#354b72' : 'transparent' }}>
            <Navbar.Brand id='mobileLogo' as={Link} to="/wedding" className="logo-center">
                <img src="/assets/images/logo/cropped/AS_logo_white_gold.png" alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav navbar-fixed-top" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="nav-tabs-custom" activeKey={location.pathname} style={{ backgroundColor: window.location.pathname === '/explore' ? '#354b72' : 'transparent', borderRadius: '1rem', padding: '0 5rem' }}>
                    <Nav.Link as={Link} to="/itinerary" className={`custom-tab ${location.pathname === '/itinerary' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Itinerary
                    </Nav.Link>
                    <Nav.Link as={Link} to="/story" className={`custom-tab ${location.pathname === '/story' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Story
                    </Nav.Link>
                    <Nav.Link as={Link} to="/hotel" className={`custom-tab ${location.pathname === '/hotel' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Hotel
                    </Nav.Link>

                    {!expanded && (
                        <Navbar.Brand as={Link} to="/wedding" className="logo-center">
                            <img src="/assets/images/logo/cropped/AS_logo_white_gold.png" alt="logo" />
                        </Navbar.Brand>
                    )}
                    <Nav.Link as={Link} to="/info" className={`custom-tab ${location.pathname === '/info' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Info
                    </Nav.Link>
                    <Nav.Link as={Link} to="/travel" className={`custom-tab ${location.pathname === '/travel' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Travel
                    </Nav.Link>
                    <Nav.Link as={Link} to="/explore" className={`custom-tab ${location.pathname === '/explore' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Explore
                    </Nav.Link>
                    <Nav.Link as={Link} to="/concierge" className={`custom-tab ${location.pathname === '/concierge' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                        Concierge
                    </Nav.Link>
                    {isAdmin && (
                        <Nav.Link as={Link} to="/admin" className={`custom-tab ${location.pathname === '/admin' ? 'active' : ''}`} onClick={() => setExpanded(false)}>
                            Admin
                        </Nav.Link>
                    )}
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default CustomNavbar;

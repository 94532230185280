// Snackbar.tsx
import React from 'react';
import '../styles/Snackbar.css'; // Custom styles for Snackbar

interface SnackbarProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, isOpen, onClose }) => {
  return (
    <div className={`snackbar ${isOpen ? 'show' : ''}`}>
      <p>{message}</p>
      <button className="close-snackbar" onClick={onClose}>
        ✕
      </button>
    </div>
  );
};

export default Snackbar;

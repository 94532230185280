// MusicContext.tsx
import React, { createContext, useContext, useEffect, useRef } from 'react';

const MusicContext = createContext<HTMLAudioElement | null>(null);

interface MusicProviderProps {
    children: React.ReactNode; // Define children prop
}

export const MusicProvider: React.FC<MusicProviderProps> = ({ children }) => {
    const audioRef = useRef<HTMLAudioElement | null>(null);

    useEffect(() => {
        // Create a new Audio object
        audioRef.current = new Audio('assets/background_music.mp3');
        audioRef.current.loop = true; // Loop the music
        audioRef.current.volume = 0.5; // Set volume

        const handleUserInteraction = () => {
            audioRef.current?.play().catch(error => console.error("Playback failed:", error));
            document.removeEventListener('click', handleUserInteraction); // Remove listener after first interaction
        };

        document.addEventListener('click', handleUserInteraction);

        return () => {
            // audioRef.current?.pause(); // Pause on unmount
        };
    }, []);

    return (
        <MusicContext.Provider value={audioRef.current}>
            {children}
        </MusicContext.Provider>
    );
};

export const useMusic = () => useContext(MusicContext);

import React, { Fragment, useEffect, useState } from 'react'
import "../styles/Default.css"
import { Navigate } from 'react-router-dom';

const Gifts: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, [])
    return (
        !showPopup ?
            <Fragment>
                <h1 className='default'>Gifts</h1>
            </Fragment>
            : <Navigate to={"/"} />

    )
}
export default Gifts
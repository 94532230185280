import React, { useEffect, useState } from 'react';
import '../styles/RsvpForm.css';
import Snackbar from './Snackbar'; // Import the custom Snackbar component

const RSVPForm: React.FC = () => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState<string>('');
  const [songRequest, setSongRequest] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);


  useEffect(() => {
    let dataLogoContianer: any = document.getElementsByClassName("logo-container")
    let dataLogoImage: any = document.getElementsByClassName("background-image")
    dataLogoContianer[0].style.display = 'none';
    dataLogoImage[0].style.display = 'none';
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    // Validate fields
    if (!name || !email || !dietaryRestrictions) {
      setError('Please fill out all required fields.');
      return;
    }

    // Clear error if validation passes
    setError(null);

    // Show the snackbar
    setSnackbarOpen(true);

    // Optionally, you can save to Supabase/MongoDB here
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <div className="rsvp-form-container">
      <img src="/assets/images/artifacts/blue_background.png" alt="background" className="background-image" />
      <h2>Join the Celebrations</h2>
      {error && <div className="error-message">{error}</div>}
      <form className="rsvp-form" onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Your Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Do you have any dietary restrictions we should be aware of?"
          value={dietaryRestrictions}
          onChange={(e) => setDietaryRestrictions(e.target.value)}
          required
        />
        <input
          type="text"
          placeholder="Song Request"
          value={songRequest}
          onChange={(e) => setSongRequest(e.target.value)}
        />
        <button className="button" type="submit">
          Submit RSVP
        </button>
      </form>

      {/* Snackbar for submission feedback */}
      {snackbarOpen && <Snackbar
        message="RSVP submitted successfully!"
        isOpen={snackbarOpen}
        onClose={handleCloseSnackbar}
      />}
    </div>
  );
};

export default RSVPForm;

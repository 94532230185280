import React from 'react';
import '../styles/PalaceView.css';

const PalaceView: React.FC = () => {
    return (
        <div className='main-image'>
            <img src="/assets/images/main_image_cropped.jpg" alt="background" />
        </div>
    );
};

export default PalaceView;

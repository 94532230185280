import React, { Fragment } from 'react';
import '../styles/Logo.css';

interface ILogoComponent {
    showLogo: boolean;
    animate: boolean;
}

const LogoComponent: React.FC<ILogoComponent> = ({ showLogo, animate }) => {
    return (
        <div className="logo-container">
            <img
                src="/assets/images/artifacts/blue_background.png"
                alt="background"
                className="background-image"
            />
            {showLogo ? (
                <Fragment>
                    <div className={`logo ${animate ? 'animate-out' : ''}`}>
                        <img src="assets/images/logo/cropped/AS_logo_white_gold.png" alt="Center Logo" />
                    </div>
                    <div className={`designRightContainer ${animate ? 'animate-out' : ''}`}>
                        <img
                            id='designRight'
                            src="/assets/images/artifacts/cropped/design_right.png"
                            alt="Design Right"
                            className="design-right"
                        />
                    </div>
                    <div className={`designLeftContainer ${animate ? 'animate-out' : ''}`}>
                        <img
                            id='designLeft'
                            src="/assets/images/artifacts/cropped/design_left.png"
                            alt="Design Left"
                            className="design-left"
                        />
                    </div>
                </Fragment>
            ) : null}
        </div>
    );
};

export default LogoComponent;

import React, { Fragment } from 'react';
import { CITY_INFO, SAFETY_INFO, EXPLORE_INFO } from '../Constants';
import '../styles/ExploreCity.css';

const ExploreCity: React.FC = () => {
    let safetyList = SAFETY_INFO.list
    let safetyTitle = SAFETY_INFO.title
    let safetyDesc = SAFETY_INFO.desc
    return (
        <div className="city-container">
            <div className='expore-main-container'>
                <h1>{EXPLORE_INFO.title}</h1>
                <p>{EXPLORE_INFO.desc}</p>
            </div>
            <img className="city-thick-border top" src="/assets/images/artifacts/cropped/bottom_border_story.png" alt="thick_border" />
            {CITY_INFO.map((city, index) => (
                <div key={index} style={{ height: 'fit-content' }}>
                    <div className="city-header">
                        <h1>{city.name}</h1>
                        <p>{city.description}</p>
                    </div>
                    <div className="city-content">
                        <img
                            src={`assets/images/explore/${city.image}`}
                            alt={city.name}
                            className="city-image"
                        />
                        <div className="city-details">
                            {city.recommendedResorts && city.recommendedResorts.length > 0 && <h3>Recommended Resorts</h3>}
                            <ul>
                                {city.recommendedResorts && city.recommendedResorts.length > 0 ? (
                                    city.recommendedResorts.map((resort, index) => (
                                        <li key={index}>{resort}</li>
                                    ))
                                ) : (
                                    null
                                )}
                            </ul>

                            {/* Optional sections based on available data */}
                            {city.recommendedHotels && city.recommendedHotels.length > 0 && (
                                <>
                                    <h3>Recommended Hotels</h3>
                                    <ul>
                                        {city.recommendedHotels.map((hotel, index) => (
                                            <li key={index}>{hotel}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {city.favoritePlacesToEat && city.favoritePlacesToEat.length > 0 && (
                                <>
                                    <h3>Favorite Places to Eat</h3>
                                    <ul>
                                        {city.favoritePlacesToEat.map((place, index) => (
                                            <li key={index}>{place}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {city.placesToEatAndDrink && city.placesToEatAndDrink.length > 0 && (
                                <>
                                    <h3>Places To Eat And Drink</h3>
                                    <ul>
                                        {city.placesToEatAndDrink.map((place, index) => (
                                            <li key={index}>{place}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {city.cocktailBars && city.cocktailBars.length > 0 && (
                                <>
                                    <h3>Cocktail Bars</h3>
                                    <ul>
                                        {city.cocktailBars.map((bars, index) => (
                                            <li key={index}>{bars}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {city.spotsToSee && city.spotsToSee.length > 0 && (
                                <>
                                    <h3>Spots To See</h3>
                                    <ul>
                                        {city.spotsToSee.map((spot, index) => (
                                            <li key={index}>{spot}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {city.spotsNotToMiss && city.spotsNotToMiss.length > 0 && (
                                <>
                                    <h3>Spots Not to Miss</h3>
                                    <ul>
                                        {city.spotsNotToMiss.map((spot, index) => (
                                            <li key={index}>{spot}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                    <img style={{ paddingBottom: city.name === 'Goa' ? 'unset' : '', marginTop: city.name === 'Ranthambore' ? '-5rem' : 0 }} className="city-thick-border" src="/assets/images/artifacts/cropped/bottom_border_story.png" alt="thick_border" />
                </div>
            ))}
            <div className='safety-container'>
                <h1>{safetyTitle}</h1>
                <p>{safetyDesc}</p>
                <ul>
                    {safetyList.map((element: string, index: number) => {
                        return (<li key={index}>{element}</li>)
                    })}
                </ul>
            </div>
            <img className="city-thick-border bottom" src="/assets/images/artifacts/cropped/bottom_border_story.png" alt="thick_border" />
        </div>
    );
};

export default ExploreCity;

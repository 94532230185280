import React, { useEffect, useState } from 'react'
import "../styles/Default.css"
import { Navigate } from 'react-router-dom';
import ExploreCity from '../components/ExploreCity';
const Explore: React.FC = () => {
    // eslint-disable-next-line
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);
    useEffect(() => {
        if (sessionStorage.getItem("isAdmin") === null || sessionStorage.getItem("isAdmin") === undefined) {
            setShowPopup(true)
        }
    }, []);

    return (
        !showPopup ?
            <ExploreCity />
            : <Navigate to={"/"} />

    )
}
export default Explore
import React, { Fragment, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import "../styles/AdminPanel.css"
import { Navigate } from 'react-router-dom';

const AdminPanel: React.FC = () => {
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    // eslint-disable-next-line
    const [showPopup, setShowPopup] = useState(false);

    useEffect(() => {
        setIsAdmin(sessionStorage.getItem("isAdmin") === "true" ? true : false);
        setShowPopup(sessionStorage.getItem("isAdmin") === "true" ? false : true);
    }, []);


    const exportToExcel = () => {
        const rsvpData = [
            { name: 'John Doe', email: 'john@example.com', song: 'Song 1' },
            { name: 'Jane Smith', email: 'jane@example.com', song: 'Song 2' },
        ];

        const worksheet = XLSX.utils.json_to_sheet(rsvpData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'RSVP Data');

        // Export the Excel file
        XLSX.writeFile(workbook, 'RSVP_Data.xlsx');
    };
    return (
        <Fragment>
            {/* <LogoComponent animate={false} showLogo={false} /> */}
            {isAdmin ?
                <Container fluid className='admin-container'>
                    <h2>Admin Panel</h2>
                    <button onClick={exportToExcel}>Export RSVP Data to Excel</button>
                </Container> : <Navigate to={"/"} />}
        </Fragment>
    );
};

export default AdminPanel;

import React, { useEffect, useState } from 'react';
import '../styles/HotelInfo.css';
import Snackbar from './Snackbar';
import { EMAIL_INFO, HOTEL_INFO } from '../Constants';

const HotelInfo: React.FC = () => {
    useEffect(() => {
        let dataLogoContianer: any = document.getElementsByClassName("logo-container")
        let dataLogoImage: any = document.getElementsByClassName("background-image")
        dataLogoContianer[0].style.display = 'none';
        dataLogoImage[0].style.display = 'none';
    });

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    return (
        <div className="hotel-info-container">
            <img className="hotel-info-heading" src="/assets/images/artifacts/cropped/hotel_info_title.png" alt="hotel_info_title" />
            {/* <img className="hotel-info-heading" src="/assets/images/artifacts/cropped/story_title.png" alt="story_title" /> */}
            <p className="hotel-info-paragraph">
                {"We have reserved all rooms at\n"}<b className='bold-text'>The Oberoi Udaivilas</b>.{"\nPlease find room details and our special wedding rates below."}<br /><br />{"To book, please send an email with your requested room type and booking code\n"} <b className='bold-text'>OC2503MSSA</b> to:
            </p>
            {EMAIL_INFO.map((info) => {
                return (<div className='info-list'>
                    <img src={info.image} alt={info.name} />
                    <a style={{ color: info.color }} href={`https://mail.google.com/mail/?view=cm&fs=1&to=${info.email}`} target='_blank' rel="noreferrer" onClick={(e) => {
                        navigator.clipboard.writeText(info.email);
                        setSnackbarOpen(true)
                    }}>{info.name}</a>
                </div>)
            })}
            <p className="hotel-info-paragraph">
                Our teams will process the reservation request and a payment link will be sent to you to finalize the booking.
            </p>
            <img className="hotel-name-heading" src="/assets/images/artifacts/cropped/oberoi_title.png" alt="oberoi_title" />
            <p style={{ textAlign: 'center', marginTop: 0 }} className="hotel-info-details">(Two Room Category Options)</p>
            <div className="hotel-info-room-list">
                {HOTEL_INFO.map((room, index) => (
                    <div className="hotel-info-roomType" key={index}>
                        <h4 className="hotel-info-roomName">{room.name}</h4>
                        <p className="hotel-info-price-details">{room.price}</p>
                        <p className="hotel-info-details">{room.description}</p>
                        <img src={room.imageSrc} alt={room.altText} className="hotel-info-roomImage" />
                        <img src='/assets/images/artifacts/cropped/seperator_icon.png' alt={room.altText} className="seperator-icon" />
                    </div>
                ))}
            </div>
            {snackbarOpen && <Snackbar
                message="Email address copied to clipboard!"
                isOpen={snackbarOpen}
                onClose={handleCloseSnackbar}
            />}
        </div>
    );
};

export default HotelInfo;

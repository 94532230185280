import React, { useEffect } from 'react';
import '../styles/ItineraryInfo.css';
import { ITINERARY_INFO } from '../Constants';

const ItineraryInfo: React.FC = () => {
    useEffect(() => {
        const targetElement = document.getElementById('itinerary_title');
        if (targetElement) {
            setTimeout(() => {
                targetElement.scrollIntoView({
                    behavior: 'smooth', // Smooth scroll
                    block: 'start',     // Align the element to the top of the viewport
                });
            }, 1000);  // Delay the scroll by 500ms (optional, you can remove this if not needed)
        }
    }, []);

    return (
        <div className="itinerary-info-container">
            <img className="itinerary-border-image" src="/assets/images/itinerary_new/cropped/thick_border.png" alt="thick_border" />
            <img className="itinerary-thin-border-image" src="/assets/images/itinerary_new/cropped/top_thin_border.png" alt="thin_border" />
            <img className="itinerary-heading" src="/assets/images/itinerary_new/cropped/wedding_itinerary_title.png" alt="itinerary_title" />
            <div className="itinerary-grid">
                {ITINERARY_INFO.map((item, index) => (
                    <div key={index} className="itinerary-column">
                        <div className="itinerary-date">{item.day}</div>
                        {item.details.map((detail, detailIndex) => (
                            <div key={detailIndex} className="itinerary-event">
                                {detail.title && <h3 className="event-title">{detail.title}</h3>}
                                {detail.description && <p className="event-description">{detail.description}</p>}
                                {detail.time && <p className="event-time">{detail.time}</p>}
                                {detail.attire && <p className="event-attire">Attire | {detail.attire}</p>}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <img className="itinerary-thin-border-image faq-thin-border-bottom" src="/assets/images/itinerary_new/cropped/top_thin_border.png" alt="thin_border" />
        </div>
    );
}

export default ItineraryInfo;
